@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  position: relative;
  width: 100%; /* Full width */
  height: 400px; /* Adjust the height as needed */
}

.group-hover .opacity-0 {
  opacity: 1;
}
